.product .item-image {
  width: 100%;
  height: 100%;
}

.m-textarea label {
  margin-top: 10px;
}

.input-modified * {
  color: limegreen !important;
  /* border-radius: 5px; */
}

.prod-list {
  cursor: pointer;
}

.prod-list:hover {
  text-decoration: underline;
}

.hover:hover {
  color: green;
}
.small-switch .MuiSwitch-track {
  height: 10px;
}

.ul-content ul {
  margin-left: 30px;
}
